<template>
  <v-card>
    <v-card-text>
      <v-text-field
        v-model="searchValue"
        single-line
        dense
        filled
        rounded
        :placeholder="$t('search')"
        clearable
        hide-details
        prepend-inner-icon="mdi-magnify"
      >
      </v-text-field>
      <div :class="isSafari ? 'emoji_picker mt-3' : 'emoji_picker mt-3 noto_color_emoji'">
        <div class="picker_container">
          <div class="category" v-for="category in categories" :key="`category_${category}`">
            <span>{{ category }}</span>
            <div class="emojis_container">
              <button
                @click="handleEmojiClick($event, emoji)"
                v-for="(emoji, index) in category_emojis(category, searchValue)"
                :key="`emoji_${index}`"
              >
                {{ emoji.char }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import data from "@/util/emojis-data.json";

export default {
  data() {
    return {
      searchValue: "",
      isSafari: !!window.GestureEvent,
    };
  },
  computed: {
    categories() {
      if (!this.searchValue) return [...new Set(data.map((e) => e.category))];
      const filtered = data.filter((e) => {
        const matchesName = e.name?.toLowerCase().includes(this.searchValue?.toLowerCase() || "");
        const matchesKeywords = e.keywords?.some((keyword) => keyword.toLowerCase().includes(this.searchValue?.toLowerCase() || ""))
        // const matchesKeywords =
        //   e.keywords &&
        //   Object.values(e.keywords).some((keywordArr) =>
        //     keywordArr.some((keyword) => keyword.toLowerCase().includes(this.searchValue?.toLowerCase() || ""))
        //   );

        return matchesName || matchesKeywords;
      });

      return [...new Set(filtered.map((e) => e.category))];
    },
    category_emojis: () => (category, searchValue) => {
      return data.filter((e) => {
        const matchesCategory = e.category === category;
        const matchesName = e.name?.toLowerCase().includes(searchValue?.toLowerCase() || "");
        const matchesKeywords = e.keywords?.some((keyword) => keyword.toLowerCase().includes(searchValue?.toLowerCase() || ""))
        // const matchesKeywords =
        //   e.keywords &&
        //   Object.values(e.keywords).some((keywordArr) =>
        //     keywordArr.some((keyword) => keyword.toLowerCase().includes(searchValue?.toLowerCase() || ""))
        //   );

        return searchValue ? matchesCategory && (matchesName || matchesKeywords) : matchesCategory;
      });
    },
  },
  methods: {
    handleEmojiClick(e, emoji) {
      e.preventDefault();
      this.$emit("emoji_click", emoji.char);
    },
  },
};
</script>

<style scoped>
:deep(.v-text-field--rounded > .v-input__control > .v-input__slot) {
  padding: 0 24px !important;
}

.emoji_picker {
  font-family: sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 20rem;
  height: 20rem;
  max-width: 100%;
}

.noto_color_emoji {
  font-family: "Noto Color Emoji", sans-serif;
}

.picker_container {
  position: relative;
  padding: 0;
  overflow: auto;
  z-index: 1;
}

.category {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  color: rgb(169, 169, 169);
}

.emojis_container {
  display: flex;
  flex-wrap: wrap;
}

.category button {
  margin: 0.5rem;
  margin-left: 0;
  background: inherit;
  border: none;
  font-size: 1.5rem;
  padding: 0;
}
</style>
